import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const wheelsFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const getActiveCurrency = () =>
  window.document.querySelector('.navPages-item.currency-selector a')?.text.trim().toLowerCase();

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'HomeVehicleWidget').columnBreakpoint = 770;
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetCategoryTiles').isVehicleWidgetDisabled = true;
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetBrandTiles').isVehicleWidgetDisabled = true;

// CSL fix
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'Garage').location = '#cm-garage';

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  SearchRequestDefaults: {
    ...bigcommerceSparkDefaults.SearchRequestDefaults,
    extra: { currency: getActiveCurrency() },
  },
  autocomplete: {
    requestDefaults: { extra: { currency: getActiveCurrency() } },
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter((w) => !['SearchVehicleWidget'].includes(w.name)),
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: '#cm-ymm-header', class: 'cm_vehicle-widget__header_container' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      visibleIf: () => !window.document.body.classList.contains('page-type-default'),
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '#productRelated-content',
        class: 'toggle-content is-open',
      },
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
  ],
};
